import axios from 'axios'
import { GeoPoint, Timestamp } from "firebase/firestore";
import _forEach from "lodash/forEach";
import _debounce from "lodash/debounce";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import store from "@/store"
import { LogUserLogin, LogEvent } from "./analytics"
import OutsetaTasks from "@/store/outsetaTasks"
import router from "@/router"

// worker promise store
var workerPromiseStore = {}
const workerPromise = (message) => {
  let promiseKey = message.promiseKey || null;
  if (!promiseKey) {
    let uid = message.key || null
    promiseKey = message.act + '_' + (uid || Date.now().toString(32))
  } 
  // console.log('workerPromise', promiseKey)
  return new Promise((resolve, reject) => { 
    workerPromiseStore[promiseKey] = (result) => {
      if (result.state === 'success') {
        // console.log(`${promiseKey}, success`)
        resolve(result)
      }
      else if (result.state === 'error') {
        console.log(`${promiseKey}, error`)
        reject(result)
      }
    }
    message.promiseKey = promiseKey
    fsWorker.postMessage(message)
  })
}

// handle return messages
let receiveMessage = (e) => {
  let m = e.data
  if (m.act === 'reportQueue') {
    let records = Object.keys(store.state.Db.assets).length
    records += Object.keys(store.state.Db.images).length
    records += Object.keys(store.state.Db.files).length
    records += m.queue['Db.assets'] || 0
    records += m.queue['Db.images'] || 0
    records += m.queue['Db.files'] || 0
    store.commit('RECORD_COUNT', records)
  }
  else if (m.act === 'set_fs') {
    store.commit('set_fs', { key: m.key, data: m.data })
    // if(m.key === 'Db.reports') console.log('set_fs',  m.key, m.data)
    if (workerPromiseStore[m.key]) {
      workerPromiseStore[m.key]({ state: 'success' })
      delete workerPromiseStore[m.key]
    }
    // }
  }
  else if (m.act === 'clear_fs') {
    store.commit('clear_fs', { key: m.key, id: m.id })
  }
  // else if(m.act === 'log') {
  //   console.log(m)
  // }
  else if (m.act === 'loginSuccess') {
    store.dispatch('loading', true)
  }
  // else if(m.act === 'fbError') {
  //   store.dispatch('fbError', m.error)
  // }
  else if (m.act === "authChanged") {
    store.dispatch("setUser", m.user)
    .then(() => {
      LogUserLogin(m.user)
    });
  }
  else if (m.act === 'outsetaToken') {
    OutsetaTasks.setToken(m.token)
  }
  else if (['bindRoots', 'bindDefaultRoots'].includes(m.act)) {
    if (workerPromiseStore[m.act]) {
      workerPromiseStore[m.act](m.response)
      delete workerPromiseStore[m.act]
    }
  }
  else if (m.act === "response") {
    // console.log('response', m)
    if (workerPromiseStore[m.key]) {
      workerPromiseStore[m.key](m.response)
      delete workerPromiseStore[m.key]
    }
    else {
      console.warn('missing callback', m.key)
    }
  }
  else {
    console.error('unhandled message', m)
  }
}

// setup firestore webworker
const envMode = process.env.NODE_ENV
var fsWorker = null
if (envMode === 'development') {
  console.log(envMode + " environment")
  console.error("TODO: setup Service Worker as Firebase handler")
}
console.warn("Firebase using worker: multi-tab persistence disabled")
fsWorker = new Worker(new URL("./fs-worker.js", import.meta.url))
fsWorker.onmessage = receiveMessage
if (!window.Worker) console.error("workers not supported by browser")

// export functions
export const manageAccount = (data) => {
  return workerPromise({act: 'manageAccount', data})
}
export const outsetaAddUser = () => {
  return new Promise((resolve, reject) => {
    let promiseKey = `outsetaAddUser_${Date.now().toString(32)}`;
    workerPromiseStore[promiseKey] = (result) => {
      if (result.state === 'success') resolve(result)
      else if (result.state === 'error') reject(result)
    }
    let message = { act: 'outsetaAddUser', uid: store.state.Auth.configUser.id, promiseKey }
    fsWorker.postMessage(message)
  })
}
export const outsetaGetToken = (subscription) => {
  return new Promise((resolve, reject) => {
    let promiseKey = `outsetaGetToken_${Date.now().toString(32)}`;
    workerPromiseStore[promiseKey] = (result) => {
      if (result.state === 'success') resolve(result)
      else if (result.state === 'error') reject(result)
    }
    let message = { act: 'outsetaGetToken', uid: store.state.Auth.configUser.id, promiseKey }
    if(subscription) {
      message.subscription = subscription
    } 
    else {
      let accountInfo = store.state.Db.org.accountInfo
      if(accountInfo && accountInfo.subscription) message.subscription = accountInfo.subscription
    }
    // console.log('outsetaGetToken', message)
    fsWorker.postMessage(message)
  })
}
export const outsetaCheckAccount = () => {
  return new Promise((resolve, reject) => {
    let promiseKey = `outsetaCheckAccount_${Date.now().toString(32)}`;
    workerPromiseStore[promiseKey] = (result) => {
      if (result.state === 'success') resolve(result)
      else if (result.state === 'error') reject(result)
    }
    let account = store.state.Db.org.account
    let configUser = store.state.Auth.configUser
    if(!account) return reject('Org does not have account set')
    if(!configUser.outsetaUID) return reject('Outseta User not configured')
    let accountInfo = (configUser.accounts || {})[account]
    let now = Timestamp.fromDate(new Date())
    if((now.seconds - (accountInfo?.ts?.seconds || 0)) < 86400 * 3) {
      console.log('Skipped account check - not due')
      return resolve(accountInfo)
    } 
    let message = { act: 'outsetaCheckAccount', uid: configUser.id, account, promiseKey }
    fsWorker.postMessage(message)
  })
}
export const unbind = async (key) => {
  if (fsWorker) fsWorker.postMessage({ act: 'unbind', key })
}
export const enableNetwork = () => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`enableNetwork`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'enableNetwork' })
    }
  })
}
export const disableNetwork = () => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`disableNetwork`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'disableNetwork' })
    }
  })
}
export const signIn = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`signIn`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'signIn', email, password })
    }
  })
}
export const signOut = () => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`signOut`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'signOut' })
    }
  })
}
export const getUser = () => {
  return workerPromise({ act: 'getUser' })
}
export const sendEmailVerification = () => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`sendEmailVerification`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'sendEmailVerification' })
    }
  })
}
export const sendPasswordReset = (email) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`sendPasswordReset`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'sendPasswordReset', email: email || null })
    }
  })
}
export const bindCollection = ({ key, collectionRef }) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[key] = (result) => {
        if (result.state === 'success') {
          resolve(result)
        }
        else if (result.state === 'error') reject(result)
      }
      let message = { act: 'bindCollection', key, collectionRef }
      fsWorker.postMessage(message)
    }
  })
}
export const bindDocument = ({ key, documentRef }) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[key] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      let message = { act: 'bindDocument', key, documentRef }
      fsWorker.postMessage(message)
    }
  })
}
export const bindDefaultRoots = ({ documentRef }) => {
  store.commit('clear_fs', { key: 'Db.assets' })
  store.commit('clear_fs', { key: 'Db.images' })
  store.commit('clear_fs', { key: 'Db.reports' })
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      const roots = store.state.Util.rootCache || []
      workerPromiseStore['bindDefaultRoots'] = (result) => {
        if (result?.state === 'error') reject(result)
        else resolve(result)
        return true
      }
      let message = { act: 'bindDefaultRoots', documentRef, roots }
      fsWorker.postMessage(message)
    }
  })
}
export const bindNewRoot = ({ root, documentRef }) => {
  return new Promise((resolve, reject) => {
    const roots = [...(store.state.Util.rootCache || [])]
    if (roots.includes(root)) {
      let ind = roots.findIndex(v => { return v === root })
      roots.splice(ind, 1)
      roots.push(root)
      resolve()
    } else {
      if (roots.length >= 5) roots.shift()
      roots.push(root)
      if (fsWorker) {
        workerPromiseStore['bindRoots'] = (result) => {
          if (result?.state === 'error') reject(result)
          else {
            store.commit('SET_ROOT_CACHE', roots) 
            resolve(result)
          }
          return true
        }
        let message = { act: 'bindRoots', documentRef, roots }
        fsWorker.postMessage(message)
      }
    }
  })
}
export const bindReservedIDs = ({ key, collectionRef, userID }) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[key] = (result) => {
        if (result.state === 'success') {
          resolve(result)
        }
        else if (result.state === 'error') reject(result)
      }
      let message = { act: 'bindReservedIDs', collectionRef, userID, key }
      fsWorker.postMessage(message)
    }
  })
}
export const queryDocs = ({ type, query }) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      let promiseKey = `queryDocs_${Date.now().toString(32)}`;
      workerPromiseStore[promiseKey] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      let ref = ['orgs', store.state.Db.org.id, 'units', store.state.Db.unit.id, type]
      let message = { act: 'getDoc', ref, key: 'Db.' + type, promiseKey, queryParams: query }
      fsWorker.postMessage(message)
    }
  })
}
export const getDoc = (ref, key, queryParams) => {
  let promiseKey = `getDoc_${Date.now().toString(32)}`;
  let message = { act: 'getDoc', ref, key, queryParams, promiseKey }
  return workerPromise(message)
}
export const getAsset = (assetID) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      let queryParams = ['assetID', '==', assetID]
      let promiseKey = `getAsset_${assetID}_${Date.now().toString(32)}`;
      workerPromiseStore[promiseKey] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      let ref = ['orgs', store.state.Db.org.id, 'units', store.state.Db.unit.id, 'assets']
      let message = { act: 'getDoc', ref, key: 'Db.assets', promiseKey, queryParams }
      fsWorker.postMessage(message)
    }
  })
}
export const setDoc = (ref, data) => {
  if (fsWorker) fsWorker.postMessage({ act: 'setDoc', ref, data })
}
export const setRecord = (ref, data) => {
  if(ref.length === 2) ref = ['orgs', store.state.Db.org.id, 'units', store.state.Db.unit.id].concat(ref)
  let message = { act: 'setDoc', ref, data }
  return workerPromise(message)
}
export const updateRecord = (ref, data) => {
  if(ref.length === 2) ref = ['orgs', store.state.Db.org.id, 'units', store.state.Db.unit.id].concat(ref)
  let message = { act: 'updateDoc', ref, data }
  return workerPromise(message)
}
export const addRecord = (ref, data) => {
  if(!Array.isArray(ref) && typeof ref === 'string') ref = [ ref ]
  if(ref.length === 1) ref = ['orgs', store.state.Db.org.id, 'units', store.state.Db.unit.id].concat(ref)
  return workerPromise({act: 'addDoc', ref, data})
}
export const updateDoc = (ref, data) => {
  let message = { act: 'updateDoc', ref, data }
  return workerPromise(message)
}
export const addDoc = (ref, data) => {
  let message = { act: 'addDoc', ref, data }
  return workerPromise(message)
}
export const deleteDoc = (ref) => {
  if(ref.length !== 6 && ref.length !== 4) return console.error('deleteDoc: looks suspect check reference')
  if(ref.length === 6 && ref[4] === 'images') console.warn(`deleteDoc [images]: TODO: consider archive.`, ref[5])
  else if(ref.length === 6) console.warn(`deleteDoc [${ref[4]}]:`, ref[5])
  if(ref.length === 4 && ref[0] !== 'users' && ref[2] !== 'orgs') return console.error('deleteDoc: ERROR should not delete records')
  let message = { act: 'deleteDoc', ref }
  return workerPromise(message)
}
export const setReportApp = (request) => {
  console.log(request)
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`setReportApp`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'setReportApp', request })
    }
  })
}
export const uploadFile = (key, data) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`uploadFile_${key}`] = (result) => {
        if (result.state === 'success') {
          console.log(`uploadFile_${key}, complete`)
          resolve(result)
        }
        else if (result.state === 'error') {
          console.log(`uploadFile_${key}, error`)
          reject(result)
        }
      }
      fsWorker.postMessage({ act: 'uploadFile', key, data })
    }
  })
}
export const deleteFile = (key) => {
  let message = { act: 'deleteFile', key }
  return workerPromise(message)
}
export const getFileURL = (key) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`getFileURL_${key}`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'getFileURL', key })
    }
  })
}
export const requestSmallImage = ({ path, refresh }) => {
  let key = path
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`requestSmallImage_${key}`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'requestSmallImage', key, refresh })
    }
  })
}
export const assetRoot = (request) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`assetRoot`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'assetRoot', request })
    }
  })
}
export const addUser = (request) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`addUser`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'addUser', request })
    }
  })
}
export const addOrganization = (request) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`addOrg`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      fsWorker.postMessage({ act: 'addOrg', data: request })
    }
  })
}
export const addUnit = (request) => {
  return new Promise((resolve, reject) => {
    if (fsWorker) {
      workerPromiseStore[`addUnit`] = (result) => {
        if (result.state === 'success') resolve(result)
        else if (result.state === 'error') reject(result)
      }
      let data = Object.assign({ org: store.state.Db.org.id }, request)
      fsWorker.postMessage({ act: 'addUnit', data })
    }
  })
}
export const authenticateApp = (data) => {
  return workerPromise({act: 'authenticateApp', data})
}
export const queryData = (request) => {
  if(!request.org) request.org = store.state.Db.org.id
  if(!request.unit) request.unit = store.state.Db.unit.id
  return workerPromise({act: 'queryData', request})
}
export const getFromStorage = (path) => {
  return new Promise(async (resolve, reject) => {
    const { url } = await getFileURL(path).catch(({ err }) => {
      switch (err.code) {
        case 'storage/object-not-found':
          console.warn(`object not found: ${path}`)
          break;
        default:
          console.warn('Unknown error occurred getting URL', err)
      }
      reject(err)
    });
    const response = await axios.get(url, {
      method: 'get',
      responseType: 'blob'
    }).catch(err => {
      console.warn(err)
      reject(err)
    })
    if (response.status !== 200) {
      console.log('response status ' + response.status)
      let err = new Error('response status ' + response.status)
      return reject(err)
    }
    // handle success
    var blob = new Blob([response.data], { type: response.headers['content-type'] })
      // console.log(`received ${path}, ${blob.size} Kb`)
    resolve(blob);
  })
}
export const downloadFromStorage = (objStore, key) => {
  return new Promise(async (resolve, reject) => {
    let storePath = `${objStore}/${key}`
    let {data, err} = await getFromStorage(storePath)
    if (err) {
      console.log(err)
      reject(err)
    }
    let url = window.URL.createObjectURL(data)
    let fileName = key
    switch (data.type) {
      case 'application/pdf':
        fileName += '.pdf'
        break;
      case 'application/json':
        fileName += '.json'
        break;
      case 'image/jpeg':
        fileName += '.jpg'
        break;
      case 'image/png':
        fileName += '.png'
        break;
      default:
        console.log('unknown type:', data.type)
    }

    if (data.type === 'application/pdf') {
      resolve({
        message: 'success',
        file: {
          url,
          type: data.type,
          name: fileName
        }
      })
    } else {
      let a = window.document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      gaLogEvent('image_download')
      resolve(true)
    }
  })
}

// const cacheFiles = (list) => {
//   return new Promise ((resolve, reject) => {
//     if(fsWorker) {
//       workerPromiseStore[`cacheFiles_${path}`] = (result) => {
//         if(result.state === 'success') resolve(result)
//         else if (result.state === 'error') reject(result)
//       }
//       fsWorker.postMessage({act: 'cacheFiles', list})
//     }
//   })
// }
// mutations for firestore webworker
export const fsMutations = {
  set_fs(state, { key, data }) {
    if (typeof data !== 'object') console.warn(`Invalid FS data should be array or object`)
    let keyArray = String(key).split('.')
    let k = keyArray[keyArray.length - 1]
    if (keyArray.length === 2) state = state[keyArray[0]]

    // convert selected collection arrays to objects
    if (['tags', 'assets', 'images', 'files', 'reports'].includes(keyArray[1])) {
      let dataObject = {}
      if (Array.isArray(data)) {
        data.forEach(r => { dataObject[r.id] = r })
      } else {
        dataObject[data.id] = data
      }
      data = dataObject
      // console.log(`setFs(${keyArray[1]})`, dataObject)
    }

    // convert new data based on vuex data type
    if (Array.isArray(state[k])) {
      if (Array.isArray(data)) {
        let newData = _cloneDeep(state[k])
        data.forEach(doc => {
          if (!doc.id) console.warn(`Invalid FS data missing 'id'`)
          let ind = newData.findIndex(r => {
            return r.id === doc.id
          })
          if (ind === -1) newData.push(doc)
          else newData.splice(ind, 1, doc)
          // if(key === 'Db.units') console.log(key, data, newData)
        })
        state[k] = newData
      }
      else if (typeof data === 'object') {
        // console.log(data)
        if (!data.id) console.warn(`Invalid FS data missing 'id'`)
        let ind = state[k].findIndex(r => { return r.id === data.id })
        if (ind === -1) state[k].push(data)
        else state[k].splice(ind, 1, data)
      }
    }
    else if (typeof state[k] === 'object') {
      if (Array.isArray(data)) {
        // console.warn(`Skipped mutations, using last object in fsData array`)
        data = data[data.length - 1]
      }
      let newData = {}
      Object.assign(newData, state[k], data)
      state[k] = newData
      if (['assets', 'images', 'files'].includes(keyArray[1])) state.count[k] = Object.keys(state[k]).length
    }
    else {
      console.warn(`Invalid FS state key  '${key}' should be array or object`)
    }
  },
  clear_fs(state, { key, id }) {
    // console.log('clear_fs', { key, id })
    let keyArray = String(key).split('.')
    let k = keyArray[keyArray.length - 1]
    if (keyArray.length === 2) state = state[keyArray[0]]
    if (typeof state[k] !== 'object') return console.warn(`Invalid FS state key '${key}' is not array or object`)
    if (Array.isArray(state[k])) {
      if (!id) return state[k] = []
      let ind = state[k].findIndex(r => { return r.id === id })
      if (ind !== -1) state[k].splice(ind, 1)
    }
    else {
      if (!id) {
        if (['assets', 'images', 'files'].includes(keyArray[1])) state.count[k] = 0
        return state[k] = {}
      }
      let newData = {}
      Object.assign(newData, state[k])
      _forEach(newData, (value, key) => {
        if (key === id) delete newData[key]
        if (value.id === id) delete newData[key]
      })
      state[k] = newData
    }
  }
}
export {
  Timestamp, GeoPoint
}
